import React from 'react';

export default function UdemyIcon() {
  return (
    <svg
      viewBox="0 0 412.77 799.91"
      xmlns="http://www.w3.org/2000/svg"
      width="1290"
      height="2500"
      className="icon"
    >
      <path
        d="M412.74 238.2l-206.4-119.1L0 238.2V119.1L206.37 0l206.4 119.1v119.1z"
        fill="#a435f0"
      />
      <path d="M0 338.22h108.11v262.54c0 67.83 50.67 100.9 98.26 100.9 48 0 98.3-33.95 98.3-101.81V338.22h108.1v268.8c0 62.48-19.65 110.7-58.96 143.76-39.33 33.04-88.45 49.13-148.35 49.13-59.84 0-108.99-16.06-147.38-49.13C19.65 717.75 0 671.32 0 609.69z" />
    </svg>
  );
}
