import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function Contact() {
  const lang = useSelector((state) => state);

  useEffect(() => {
    document.title = `Axel Rasmussen | ${
      lang === 'en' ? 'Contact' : 'Contacto'
    }`;
  }, [lang]);

  return (
    <section className="contact">
      <h2 className="contact__title heading-2">
        {lang === 'en' ? 'Contact me' : 'Contáctame'}
      </h2>

      <p className="contact__description">
        {lang === 'en'
          ? 'If you would like to get in contact with me, please message me via'
          : 'Si quisieras ponerte en contacto, porfavor mándame un mensaje via'}{' '}
        <span className="contact__link link">
          <a
            href="https://www.linkedin.com/in/axel-rasmussen-toniolo/"
            target="_blank"
          >
            LinkedIn
          </a>
        </span>
        .
      </p>
    </section>
  );
}
