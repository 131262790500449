import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export default function Nav() {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state);

  function handleToggle() {
    dispatch({ type: 'toggle' });
  }

  return (
    <nav className="nav">
      <ul>
        <NavLink activeClassName="selected" to="/home">
          <li>{lang === 'en' ? 'Home' : 'Inicio'}</li>
        </NavLink>

        <NavLink activeClassName="selected" to="/about">
          <li>{lang === 'en' ? 'About' : 'Info'}</li>
        </NavLink>

        <NavLink activeClassName="selected" to="/projects">
          <li>{lang === 'en' ? 'Projects' : 'Proyectos'}</li>
        </NavLink>

        <NavLink activeClassName="selected" to="/contact">
          <li>{lang === 'en' ? 'Contact' : 'Contacto'}</li>
        </NavLink>
      </ul>

      <button className="nav__button button" onClick={handleToggle}>{`${
        lang === 'en' ? 'ESPAÑOL' : 'ENGLISH'
      }`}</button>
    </nav>
  );
}
