import React, { useRef, useState } from 'react';
import LinkIcon from './Icons/LinkIcon';
import { useSelector } from 'react-redux';
import LoadingIcon from './Icons/LoadingIcon';

export default function Project(props) {
  const lang = useSelector((state) => state);
  const [loaded, setLoaded] = useState(false);

  const ref = useRef();

  function handleOnLoad() {
    setLoaded(true);
  }

  function handleProjectClick() {
    ref.current.closest('.project').classList.toggle('opened');
  }

  return (
    <div className="project">
      <img
        onLoad={handleOnLoad}
        onClick={handleProjectClick}
        ref={ref}
        className="project__image"
        src={props.image}
        alt={`${props.name} image`}
        style={!loaded ? { display: 'none' } : {}}
      />

      {!loaded ? (
        <div className="project__image">
          <LoadingIcon />
        </div>
      ) : (
        ''
      )}

      <div className="project__content">
        <p className="project__name">{props.name}</p>
        <p className="project__description">{props.description}</p>

        <div className="project__links">
          {props.code !== 'denied' ? (
            <a
              href={props.code}
              target="_blank"
              className="button project__link"
            >
              {lang === 'en' ? 'Code' : 'Código'} <LinkIcon />
            </a>
          ) : (
            ''
          )}
          <a href={props.demo} target="_blank" className="button project__link">
            Demo <LinkIcon />
          </a>
        </div>
      </div>
    </div>
  );
}
