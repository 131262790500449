import React from 'react';
import LinkIcon from './Icons/LinkIcon';
import { useSelector } from 'react-redux';

export default function Certification(props) {
  const lang = useSelector((state) => state);
  const Icon = props.icon;

  return (
    <div className="certification">
      <div className="certification__logo">
        <Icon />
      </div>

      <div className="certification__data">
        <p className="certification__name">{props.name}</p>
        <p className="certification__instructor">
          {lang === 'en' ? 'by' : 'por'} {props.instructor}
        </p>
      </div>

      <a
        href={props.certificate}
        target="_blank"
        className="button certification__button"
      >
        {lang === 'en' ? 'Certificate' : 'Certificación'} <LinkIcon />
      </a>
    </div>
  );
}
