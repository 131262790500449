import { useEffect } from 'react';
import Project from '../components/Project';
import { useSelector } from 'react-redux';

const weatherLookupImg = require('../img/weather-lookup.png');
const mobiliaRaffinataImg = require('../img/mobilia-raffinata.png');
const allNewsImg = require('../img/all-news.png');

const projectsData = require('../projects.json');

export default function Projects() {
  const lang = useSelector((state) => state);

  useEffect(() => {
    document.title = `Axel Rasmussen | ${
      lang === 'en' ? 'Projects' : 'Proyectos'
    }`;
  }, [lang]);

  const weatherLookupDescEn =
    "My first project. It's an app to lookup the weather in any part of the world via clicking on the map or entering the name of a city; you can bookmark any location you fancy. I started off by making it in vanilla JavaScript, but, as I learned more about React, I translated the code to the superior choice. It also uses various API's and localStorage for the bookmarks, and it was my first time using them too.";

  const weatherLookupDescEs =
    "Mi primer proyecto. Es una app que te permite conseguir el clima en cualquier parte del mundo con un solo click en la localidad que quieras o escribiendo el nombre de una ciudad; podes guardar cualquier lugar que te apetezca. Empecé escribiendolo en JavaScript vanilla, pero, mientras que aprendía más sobre React, traduje el código a la elección superior. También utiliza varias API's y localStorage para las localidades guardadas, y también era mi primera vez usando estos utensilios.";

  const mobiliaRaffinataDescEn =
    'E-commerce / company site. The e-commerce section has filtering, search and sorting features. Did it a while back, so the code is a bit inadequate but it does the job. Having learned Redux, the Context API and React Router, I can think of a far better way of writing this.';

  const mobiliaRaffinataDescEs =
    'Página de e-commerce / companía. La sección de e-commerce tiene herramientas de filtros, busqueda y ordenación. Lo hice hace bastante tiempo, por ende el código es un poco inadecuado pero hace su trabajo. Habido aprendido Redux, Context API y React Router, puedo pensar una forma mucho superior de escribir el código.';

  const allNewsDescEn =
    "News app with an advanced query, but, due to the NewsAPI policy, it's not available in production; a shame, the API of this app was really fun to use. Apart from the advanced query, you can choose the country where the news are coming from, pick the category of the news, etc.";

  const allNewsDescEs =
    'App de noticias con una busqueda avanzada, pero, debido a la politica de NewsAPI, no está habilitada en producción; una pena, el API de esta app fue muy divertida de usar. Aparte de la busqueda avanzada, puedes elegir el país de donde provienen las noticias, elegir la categoria de dichas noticias, etc.';

  return (
    <section className="projects">
      <h2 className="projects__title heading-2">
        {lang === 'en' ? 'Projects' : 'Proyectos'}
      </h2>

      <div className="projects__list">
        {projectsData.map((proj) => (
          <Project
            image={require(`../img/${proj.img}`)}
            name={proj.name}
            description={
              lang === 'en' ? proj.en.description : proj.es.desciprtion
            }
            code={proj.code || 'denied'}
            demo={proj.demo}
          />
        ))}
      </div>
    </section>
  );
}
