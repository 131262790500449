import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Header() {
  const history = useHistory();
  const lang = useSelector((state) => state);

  useEffect(() => {
    document.title = `Axel Rasmussen | ${lang === 'en' ? 'Home' : 'Inicio'}`;
  }, [lang]);

  function onContactHandler() {
    history.push('/contact');
  }

  return (
    <section className="header">
      <section className="header__content">
        <h1 className="header__title">Axel Rasmussen</h1>
        <h2 className="header__role">
          {lang === 'en' ? 'Frontend Developer' : 'Desarrollador de Frontend'}
        </h2>
        <button onClick={onContactHandler} className="header__button button">
          {lang === 'en' ? "Let's work together" : 'Trabajemos juntos'} &rarr;
        </button>
      </section>
    </section>
  );
}
