import Certification from '../components/Certification';
import UdemyIcon from '../components/Icons/UdemyIcon';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Image from '../components/Image';

export default function About() {
  const lang = useSelector((state) => state);

  useEffect(() => {
    document.title = `Axel Rasmussen | ${lang === 'en' ? 'About' : 'Info'}`;
  }, [lang]);

  return (
    <section className="about">
      <h2 className="about__title heading-2">
        {lang === 'en' ? 'About Me' : 'Sobre Mi'}
      </h2>

      <p className="about__description about__description--intro">
        {lang === 'en'
          ? "I'm a self-taught frontend developer in Uruguay."
          : 'Soy un desarrollador de frontend autodidacta en Uruguay'}
      </p>

      <div className="about__image">
        <Image
          className="about__image-content"
          src={require('../img/pfp.jpg')}
          alt="pfp"
        />
      </div>

      <p className="about__description about__description--par-1">
        {lang === 'en'
          ? 'I started learning frontend development with freeCodeCamp, going through with'
          : 'Empecé a aprender desarrollo de frontend con freeCodeCamp, haciendo las certificaciones'}{' '}
        <span className="about__link link">
          <a
            href="https://www.freecodecamp.org/certification/axelras/responsive-web-design"
            target="_blank"
          >
            Responsive Web Design
          </a>
        </span>
        {', '}
        <span className="about__link link">
          <a
            href="https://www.freecodecamp.org/certification/axelras/javascript-algorithms-and-data-structures"
            target="_blank"
          >
            JavaScript Algorithms and Data Structures
          </a>
        </span>
        {lang === 'en' ? ' and ' : ' y '}
        <span className="about__link link">
          <a
            href="https://www.freecodecamp.org/certification/axelras/front-end-development-libraries"
            target="_blank"
          >
            Front End Development Libraries
          </a>
        </span>
        {lang === 'en'
          ? ' certifications; they served me as a basic itroduction to the beautiful world of frontend work. Wishing to learn more, I started to take and finish courses on Udemy such as:'
          : '. Me sirvieron como una introducción básica al hermoso mundo del trabajo de frontend. Deseando aprender más, empecé a tomar y terminar cursos en Udemy como:'}
      </p>

      <div className="about__certifications">
        <Certification
          name="The Complete JavaScript Course 2021: From Zero to Expert!"
          instructor="Jonas Schmedtmann"
          certificate="https://www.udemy.com/certificate/UC-ca330ed8-7adb-41d2-958b-bb390fdfc615/"
          icon={UdemyIcon}
        />

        <Certification
          name="Advanced CSS and Sass: Flexbox, Grid, Animations and More!"
          instructor="Jonas Schmedtmann"
          certificate="https://www.udemy.com/certificate/UC-847551a7-cfe1-4e0f-99b9-426c2334fc29/"
          icon={UdemyIcon}
        />

        <Certification
          name={`React - The Complete Guide (incl Hooks, React Router, Redux)`}
          instructor="Maximilian Schwarzmüller"
          certificate="https://www.udemy.com/certificate/UC-080f28f0-f968-4157-99e5-d75013926a46/"
          icon={UdemyIcon}
        />
      </div>
    </section>
  );
}
