const redux = require('redux');

function languageReducer(prevState = 'en', action) {
  if (action.type === 'toggle') {
    const targetLang = prevState === 'en' ? 'es' : 'en';

    localStorage.setItem('lang', targetLang);
    return targetLang;
  }

  if (action.type === 'en') {
    localStorage.setItem('lang', 'en');
    return 'en';
  }

  if (action.type === 'es') {
    localStorage.setItem('lang', 'es');
    return 'es';
  }

  return prevState;
}

export const store = redux.createStore(languageReducer);
